import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que esdevé pla amb els marges més o manco aixecats i ondulats que pot arribar als 15 cm de diàmetre. Viscós i lluent en temps humit, de color grisenc un poc blavós cap al marge; a vegades un poc violaci o rosat liliaci pàl·lid. Color crema blanquinós al centre. Les làmines, blanquinoses, són nombroses i atapeïdes, amb laminetes més curtes intercalades, algunes forcades, que toquen el peu o bé són lliures, semblen elàstiques, perquè no es rompen, sinó que es vinclen quan passem els dits sobre elles. El peu és més curt que el capell, un poc gruixut, cilíndric i atenuat cap a la base, de color blanquinós tornant fosc quan es toca. Les espores són de color crema en massa, de subgloboses a el·lipsoides, amb berrugues, de 6-10 x 5-8,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      